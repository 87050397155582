import React from 'react';
import { Popup } from '@progress/kendo-react-popup';
import ClickAwayListener from 'react-click-away-listener';

class AddWidgetMenu extends React.Component {
 anchor = null;
 constructor(props) {
     super(props);
     this.state = { show: false };
     this.deleteChart = this.deleteChart.bind(this);

 }

 componentDidMount() {
    //  this.setState({show: false});
 }

 popupAlign = { horizontal: 'right', vertical: 'bottom'};
 anchorAlign = { horizontal: 'center', vertical: 'top'};

 deleteChart(e){
    e.preventDefault();
    // this.setState({show:false});
    this.props.onClick();
 }

 addStatOrChart(e, widget){
  console.log(widget);
    if (widget === 'stat')
      this.props.addStat();
    else if (widget === 'chart')
      this.props.addChart();
    else if (widget === 'dataexplorer')
      this.props.addDataExplorer();
    else 
      this.props.addTab(e, widget, undefined);
      

    this.close();
 }

 render() {
  return (
    <span className="ag-add-widget-button">
      <button id={this.props.menuId}
        className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--colored mdl-shadow--4dp mdl-color--accent"
        onClick={this.close}
        ref={(button) => {
           this.anchor = button;
        }
       }
      >
        <i className="material-icons" role="presentation">add</i>
          <span className="visuallyhidden">
            {this.props.intl.formatMessage({id:'app.chart.addWidgetMenu.add', defaultMessage:'Add'})}
          </span>
      </button>
      <Popup
        popupAlign = {this.popupAlign}
        anchorAlign = {this.anchorAlign}
        anchor={this.anchor}
        show={this.state.show}
        animate = {false}
        popupClass={'ag-chart-popup'}
        onFocusOut = {this.onClick}
      >
        <ClickAwayListener onClickAway={this.close}>
        <ul>
          <li onClick={(e) => this.addStatOrChart(e, 'stat')}>
            <a href="#dummy">
              <i className='material-icons' style={{verticalAlign:'sub'}}>table_chart</i>&nbsp;
                {this.props.intl.formatMessage({id:'app.chart.addWidgetMenu.stat', defaultMessage:'Stat'})} 
            </a>
          </li>
          <li onClick={(e) => this.addStatOrChart(e, 'chart')}>
            <a href="#dummy">
              <i className='material-icons' style={{verticalAlign:'sub'}}>insert_chart_outlined</i>&nbsp;
                {this.props.intl.formatMessage({id:'app.chart.addWidgetMenu.chart', defaultMessage:'Chart'})} 
            </a>
          </li>
          <li onClick={(e) => this.addStatOrChart(e, 'dataexplorer')}>
            <a href="#dummy">
              <i className='material-icons' style={{verticalAlign:'sub'}}>analytics</i>&nbsp;
              {this.props.intl.formatMessage({id:'app.chart.addWidgetMenu.dataexplorer', defaultMessage:'DataTable'})} 
            </a>
          </li>
          <li onClick={(e) => this.addStatOrChart(e, 'new')}>
            <a href="#dummy">
              <i className='material-icons' style={{verticalAlign:'sub'}}>dashboard</i>&nbsp;
              {this.props.intl.formatMessage({id:'app.chart.addWidgetMenu.dashboard', defaultMessage:'Dashboard'})} 
            </a>
          </li>
        </ul>
        </ClickAwayListener>
      </Popup>
    </span>
  );
 }

  close = (e) => {
    if (e)
      this.setState({show: !this.state.show});
    else
      this.setState({show: false});
  }
}

export default AddWidgetMenu;