import React from 'react';
import { DataExplorer } from '@agviewer/data-explorer';
import '@agviewer/data-explorer/dist/index.css';

const DataExplorerWidget = ({explorer, onDelete}) => {
  return (
    <div className="w-full mdl-card mdl-shadow--2dp h-full flex flex-col">
      <div className="widget-content flex-1">
        <DataExplorer explorer={explorer} onDelete={onDelete} height='450px'/>
      </div>
    </div>
  );
};

export default DataExplorerWidget;