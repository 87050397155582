import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../errorBoundaries/errorBoundary';
import WidgetDialog from '../charts/WidgetDialog';
import ChartContainer from '../charts/ChartContainer';
import AddStatDialog from '../stats/AddStatDialog';
import Stat from '../stats/Stat';
import loading from '../loading.svg';
import { getAuthToken, BASEURI, advancedFilter } from '../utils';
import Modal from './Modal';
import AddWidgetMenu from './AddWidgetMenu';
import { RefreshPortal } from './Layout';
import RefreshButton from './RefreshButton';
import { FormattedMessage, injectIntl } from 'react-intl';
import Spinner from './Spinner';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import DataExplorerWidget from '../dataexplorer/DataExplorerWidget';


class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      title: 'AgViewer',
      visible: false,
      showAddChartDialog: false,
      charts: [],
      stats: [],
      sensors: [],
      stations: [],
      loading: false,
      loadingSensors: false,
      initialized: undefined,
      statsInitialized: undefined,
      showAddStatDialog: false,
      notInitial: false,
      station: null,
      isWorking: {
        loadCharts: false,
      },
      editingChart: {
        name: '',
        min_date: new Date(new Date().setDate(new Date().getDate() - 7)),
        max_date: new Date(),
        maximized: false,
        daterange: { start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() }
      },
      dataExplorers: [],
    };
    this.sensors = [];
    this.loadCharts = this.loadCharts.bind(this);
    this.openAddChartDialog = this.openAddChartDialog.bind(this);
    this.closeAddChartDialog = this.closeAddChartDialog.bind(this);
    this.addChart = this.addChart.bind(this);
    this.handleStationSelect = this.handleStationSelect.bind(this);
    this.deleteChart = this.deleteChart.bind(this);
    this.openAddStatDialog = this.openAddStatDialog.bind(this);
    this.closeAddStatDialog = this.closeAddStatDialog.bind(this);
    this.addStat = this.addStat.bind(this);
    this.deleteStatWidget = this.deleteStatWidget.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.editChart = this.editChart.bind(this);
    this.updateChart = this.updateChart.bind(this);
    this.handleChartChange = this.handleChartChange.bind(this);
    this.deleteDataExplorer = this.deleteDataExplorer.bind(this);
    this.addDataExplorer = this.addDataExplorer.bind(this);

  }


  refresh = (e) => {
    if (this.props.isActive) {
      console.log('re-loading');
      this.loadStats();
      this.loadCharts();
      this.props.loadStations();
    }
  }

  componentDidMount() {
    // Load stats on component mount.
    if (this.props.isActive)
      this.setState({
        initialized: false,
        statsInitialized: false
      })

  }

  componentDidUpdate() {

    // Load stats if dashboard is active.
    if (this.props.isActive && !this.state.statsInitialized)
      this.loadStats()

    // Load charts if dashboard is active.
    if (this.props.isActive && !this.state.initialized)
      this.loadCharts();

  }

  listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: '1em' }}>
        <img src={loading} style={{ width: '25px', opacity: .5 }} alt='loading' />
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  }

  handleFilterChange(e) {
    advancedFilter(
      e.filter.value,
      this.sensors,
      (items) => this.setState({ sensors: items }),
      ['name', 'port'])
  }

  addChart() {

    this.setState({ saving: true })

    let url = `${BASEURI}/api/charts/${this.props.tabId}/`,
      options = {
        method: 'POST',
        body: JSON.stringify(this.state.editingChart),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((widget) => {
            this.setState((state, props) => {
              let charts = state.charts.slice();
              charts.push(widget);
              return {
                charts: charts,
                saving: false
              };
            });

            this.closeAddChartDialog();

          })
          .catch(error => {
            this.setState({ saving: false });
            console.log('Error:', error);
          });

      });
  }

  addStat(widget) {

    this.setState({ saving: true });

    let url = `${BASEURI}/api/stats/widgets/${this.props.tabId}/`,
      options = {
        method: 'POST',
        body: JSON.stringify(widget),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((widget) => {
            this.setState((state, props) => {
              let stats = state.stats.slice();
              stats.push(widget);
              return {
                stats: stats,
                saving: false
              };
            });

            this.closeAddStatDialog();

          })
          .catch(error => {
            this.setState({ saving: false });
            console.log('Error:', error);
          });

      });


  }

  openAddChartDialog() {
    this.setState({
      showAddChartDialog: true,
      editingChart: {
        name: '',
        min_date: new Date(new Date().setDate(new Date().getDate() - 7)),
        max_date: new Date(),
        maximized: false,
        daterange: { start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() }
      }
    });
  }

  closeAddChartDialog() {
    this.setState({
      showAddChartDialog: false
    });
  }

  openAddStatDialog() {
    this.setState({
      showAddStatDialog: true
    });
  }

  closeAddStatDialog() {
    this.setState({
      showAddStatDialog: false
    });
  }

  loadCharts() {
    this.setState({
      loading: true,
      initialized: true
    });

    let url = `${BASEURI}/api/charts/${this.props.tabId}/`;
    let dataExplorerUrl = `${BASEURI}/api/dataexplorer/dataexplorers/?tab=${this.props.tabId}`;
    let options = {
      method: 'GET',
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      }
    };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {
        options.headers.Authorization = `Bearer ${token}`;

        // Load both charts and data explorers
        Promise.all([
          fetch(url, options),
          fetch(dataExplorerUrl, options)
        ])
          .then(([chartsResponse, explorersResponse]) => 
            Promise.all([chartsResponse.json(), explorersResponse.json()])
          )
          .then(([charts, explorers]) => {
            this.setState({
              charts,
              dataExplorers: explorers,
              initialized: true,
              loading: false,
            });
          })
          .catch(error => {
            console.error('Error:', error);
            this.setState({ loading: false });
          });
      });
  }

  handleStationSelect(event, selectedStation = null) {
    let station;
    if (selectedStation !== null) {
      station = selectedStation
    }
    else {
      if (event.target.value) { station = event.target.value; }
      else {
        this.setState({ station: null, });
        return;
      }
    }
    this.setState({ station: station, });
    if (station.serial) {
      let url = `${BASEURI}/api/stations/sensors/${station.serial}/`,
        options = {
          method: 'GET',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          }
        };

      return getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

          options.headers.Authorization = `Bearer  ${token}`;

          return fetch(url, options)
            .then((response) => {
              return response.json();
            })
            .then((sensors) => {
              if (sensors.length) {
                this.setState({
                  sensors: sensors,
                  loadingSensors: false
                });
                this.sensors = sensors;
              }
            })

        });
    }
  }

  deleteChart(chart) {

    // log(chart);

    let url = `${BASEURI}/api/chart/${chart}/`,
      options = {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      }

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then(response => {
            let chartList = this.state.charts.slice();
            let chartToDelete = null;
            for (let i = 0; i < chartList.length; i++) {
              if (chartList[i].id === chart)
                chartToDelete = i;
            }

            chartList.splice(chartToDelete, 1);

            this.setState({ charts: chartList });
          });

      });

  }

  editChart(chart) {

    let chartList = this.state.charts.slice();

    for (let i = 0; i < chartList.length; i++) {
      if (chartList[i].id === chart) {
        let chartToEdit = Object.assign({}, chartList[i]);
        chartToEdit.daterange = { start: new Date(chartToEdit.min_date), end: new Date(chartToEdit.max_date) };
        this.setState({ editingChart: chartToEdit, showAddChartDialog: true });
      }
    }
  }

  handleChartChange = (event) => {

    let chart = Object.assign({}, this.state.editingChart);
    // this.setState({daterange:event.target.value});
    if (event.target.name === 'default_range') {

      chart[event.target.name] = event.target.value.value;
      chart['fixed_start_date'] = null;

    } else {
      chart[event.target.name] = event.target.value;
    }

    this.setState({ editingChart: chart }, () => console.log(this.state.editingChart));
  }

  updateChart(e, chart) {

    // let chartList = this.state.charts.slice(),
    // chartToEdit = null;

    // for (let i = 0;i < chartList.length; i++){
    //   if (chartList[i].id === chart)
    //     chartToEdit = i;
    // }
    let chartList = this.state.charts.slice(),
      index, chartToUpdate;

    for (index = 0; index < chartList.length; index++) {
      if (chart && chartList[index].id === chart) {
        chartToUpdate = chartList[index];
        chartToUpdate.maximized = !chartToUpdate.maximized;
        chartList.splice(index, 1, chartToUpdate);
        break;
      } else if (!chart && chartList[index].id === this.state.editingChart.id) {
        chartToUpdate = this.state.editingChart;
        chartList.splice(index, 1, chartToUpdate);
        break;
      }
    }

    this.setState({ charts: chartList, showAddChartDialog: false });

    delete chartToUpdate.daterange;
    delete chartToUpdate.graphs;


    let url = `${BASEURI}/api/chart/${chartToUpdate.id}/`,
      options = {
        method: 'PUT',
        body: JSON.stringify(chartToUpdate),
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      }

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then(chart => {

            chartList.splice(index, 1, chart);

            this.setState({ charts: chartList });
          });

      });

  }

  loadStats() {

    this.setState({
      loading: true,
      statsInitialized: true,
      stats: []
    });

    let url = `${BASEURI}/api/stats/widgets/${this.props.tabId}/`,
      options = {
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => {
            return response.json();
          })
          .then((stats) => {
            this.setState({
              stats: stats
            });
          })
          .catch(error => console.error('Error:', error));

      });

  }

  deleteStatWidget(stat) {
    let url = `${BASEURI}/api/stats/widget/rud/${stat}/`,
      options = {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      }

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {

        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then(response => {
            let statList = this.state.stats.slice();
            let statToDelete = null;
            for (let i = 0; i < statList.length; i++) {
              if (statList[i].id === stat) {
                statToDelete = i;
                break;
              }
            }

            statList.splice(statToDelete, 1);

            this.setState({ stats: statList, editing: false });
          });

      });
  }

  deleteDataExplorer = (id) => {
    let url = `${BASEURI}/api/dataexplorer/dataexplorers/${id}/`;
    let options = {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {
        options.headers.Authorization = `Bearer ${token}`;

        fetch(url, options)
          .then(response => {
            let explorers = this.state.dataExplorers.filter(
              explorer => explorer.id !== id
            );
            this.setState({ dataExplorers: explorers });
          })
          .catch(error => console.error('Error:', error));
      });
  }


  addDataExplorer() {
    this.setState({ saving: true });

    let url = `${BASEURI}/api/dataexplorer/dataexplorers/`,
      options = {
        method: 'POST',
        body: JSON.stringify({
          name: "New Data Table",
          tab: this.props.tabId,
          view_mode: "time_series",
          sampling_interval: "raw",
          selected_measurements: [],
          date_range_start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(), // 7 days ago
          date_range_end: new Date().toISOString(),
        }),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };

    getAuthToken()
      .then(token => token)
      .catch(token => token)
      .then(token => {
        options.headers.Authorization = `Bearer  ${token}`;

        fetch(url, options)
          .then((response) => response.json())
          .then((explorer) => {
            this.setState((state) => ({ 
              dataExplorers: [...state.dataExplorers, explorer],
              saving: false
            }));
          })
          .catch(error => {
            this.setState({ saving: false });
            console.error('Error:', error); 
          });
      });
  }


  render() {
    const tab = this.props.tabs.find((item) => this.props.tabId === item.id);
    let charts = this.state.charts.map((chart) => <ErrorBoundary
      key={chart.id}>
      <ChartContainer
        locale={this.props.locale}
        intl={this.props.intl}
        handleMarkersFilterChange={this.props.handleMarkersFilterChange}
        loadingWeatherVariables={this.props.loadingWeatherVariables}
        weatherVariables={this.props.weatherVariables}
        loadingMarkers={this.props.loadingMarkers}
        markers={this.props.markers}
        delete={this.deleteChart}
        name={chart.name}
        chartId={chart.id}
        prescription={chart.prescription}
        key={chart.id}
        graphs={chart.graphs}
        sensors={this.state.sensors}
        handleStationChange={this.handleStationSelect}
        stations={this.props.stations}
        setStations={this.props.setStations}
        allStations={this.props.allStations}
        station={this.state.station}
        filter={this.handleFilterChange}
        editChart={this.editChart}
        maximized={chart.maximized}
        updateChart={this.updateChart}
        handleChange={this.handleChartChange}
        noData={this.listNoDataRender}
        loadingSensors={this.state.loadingSensors}
        editWidgets={this.props.editWidgets}
      />
    </ErrorBoundary>),
      stats = this.state.stats.map((widget) => <ErrorBoundary
        key={widget.id}>
        <Stat
          editWidgets={this.props.editWidgets}
          intl={this.props.intl}
          markers={this.props.markers}
          handleMarkersFilterChange={this.props.handleMarkersFilterChange}
          loadingMarkers={this.props.loadingMarkers}
          loadingWeatherVariables={this.props.loadingWeatherVariables}
          weatherVariables={this.props.weatherVariables}
          stations={this.props.stations}
          setStations={this.props.setStations}
          allStations={this.props.allStations}
          station={this.state.station}
          handleStationChange={this.handleStationSelect}
          statWidgetId={widget.id}
          key={widget.id}
          widget={widget}
          sensors={this.state.sensors}
          delete={this.deleteStatWidget}
          filter={this.handleFilterChange}
          noData={this.listNoDataRender}
          loadingSensors={this.state.loadingSensors}
        />
      </ErrorBoundary>);

    console.log('data explorers', this.state.dataExplorers);

    return (

      <section className={"mdl-layout__tab-panel is-active"} id={"tab" + this.props.tabId}>
        <div className='mdl-grid'>
          <div className='breadcrumb mdl-grid mdl-cell mdl-cell--12-col'>
            <FormattedMessage id='app.dash.nav' defaultMessage='DASHBOARDS' /> &nbsp; > &nbsp; {this.props.tabName ? this.props.tabName : (tab && tab.name)}
          </div>
        </div>
        <div className='flex flex-wrap flex-row gap-10 p-4'>
          {stats}
        </div>

        {/* Add data explorers here, after stats */}
        <div className='flex flex-wrap flex-row gap-10 p-4'>
          {this.state.dataExplorers.map(explorer => (
            <ErrorBoundary key={explorer.id}>
              <DataExplorerWidget
                explorer={explorer}
                onDelete={() => this.deleteDataExplorer(explorer.id)}
              />
            </ErrorBoundary>
          ))}
        </div>

        <div className="mdl-grid">
          {this.props.generating && <div className="text-gray-600 font-semibold animate-pulse text-center w-full flex items-center justify-center">
            <i className="material-icons">bar_chart</i> <FormattedMessage id='app.dash.generating_charts_message' defaultMessage='Generating charts... please wait a moment.' />
          </div>}
          {
            this.state.loading
              ?
              <Spinner
                color={'black'}
                show={true}
                backDrop={true}
                backDropColor={'white'}
                backDropOpacity={0.6}
                size={30}
              />
              :
              charts
          }
          {
            this.props.isActive &&
            <RefreshPortal>
              <RefreshButton loading={this.state.loading} onClick={this.refresh} />
            </RefreshPortal>
          }

        </div>

        <AddWidgetMenu
          intl={this.props.intl}
          addChart={this.openAddChartDialog}
          addStat={this.openAddStatDialog}
          addTab={this.props.openTabDialog}
          addDataExplorer={this.addDataExplorer}
        />

        <Modal>
          <WidgetDialog
            saving={this.state.saving}
            intl={this.props.intl}
            visible={this.state.showAddChartDialog}
            close={this.closeAddChartDialog}
            addChart={this.addChart}
            chart={this.state.editingChart}
            updateChart={this.updateChart}
            handleChange={this.handleChartChange}
          />
          <AddStatDialog
            saving={this.state.saving}
            intl={this.props.intl}
            visible={this.state.showAddStatDialog}
            close={this.closeAddStatDialog}
            addWidget={this.addStat}
          />


        </Modal>

      </section>

    );
  }
}

function DashboardWraper(props) {
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();
  const location = useLocation();
  const { tabName, deviceSerial, generateCharts } = location.state || {};

  useEffect(() => {

    if (generateCharts) {
      setGenerating(true);
      let url = `${BASEURI}/api/summary/charts/${deviceSerial}/generate/`,
        options = {
          method: 'POST',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          }
        };

      getAuthToken()
        .then(token => token)
        .catch(token => token)
        .then(token => {

          options.headers.Authorization = `Bearer  ${token}`;

          fetch(url, options)
            .then((response) => {
              return response.json();
            })
            .then(({ tab }) => {
              setGenerating(false);
              navigate(`/dashboard/${tab}`, { state: { tabName, deviceSerial, generateCharts: false } })

            })
            .catch(error => console.error('Error:', error));
        });
    }
  }, [])

  return <Dashboard {...props} key={id} tabId={id} tabName={tabName} generating={generating} />
}


export default DashboardWraper;