import React, { Component } from 'react';
import { getAuthToken, formatDate, BASEURI } from '../utils';
import Modal from '../general/Modal';
import StatMenu from './StatMenu';
import StatModalForm from './StatDialog';
import { toZonedTime, toDate } from 'date-fns-tz';



class StatItem extends Component {
	constructor(props) {
		super(props);
		this.handleStatClick = this.handleStatClick.bind(this);
		this.getRange = this.getRange.bind(this);
	}

	handleStatClick() {
		this.props.onClick(this.props.statId, this.props.station);
	}

	getRange(min_date, max_date) {

		if (this.props.data.function === 'Cumulative Sum')
			return this.props.data.reset_on


		const range = parseInt(Math.abs(new Date(max_date) - new Date(min_date)) / (1000 * 60 * 60 * 24));

		if (range >= 360) {
			return this.props.intl.formatMessage({ id: 'app.statItem.1year', defaultMessage: '1Y' });
		}

		if (range >= 180 && range < 360) {
			return this.props.intl.formatMessage({ id: 'app.statItem.6months', defaultMessage: '6M' });
		}

		if (range === 30) {
			return this.props.intl.formatMessage({ id: 'app.statItem.1month', defaultMessage: '1M' });
		}

		if (range === 7) {
			return this.props.intl.formatMessage({ id: 'app.statItem.1week', defaultMessage: '1W' });
		}

		if (range === 2) {
			return this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.48hours', defaultMessage: '48H' });
		}
		if (range === 1) {
			return this.props.intl.formatMessage({ id: 'app.chart.quickDateMenu.24hours', defaultMessage: '24H' });
		}

		return 'N/A'

	}


	render() {
		let range = this.getRange(this.props.data.min_date, this.props.data.max_date)
		return (
			<tr
				onClick={this.handleStatClick}
				className="flex-wrap border-b border-b-[#9CA3AF33]/[0.20] p-2  last:border-0"
			>
				<td className="pl-3 pr-2 py-2">
					<div
						className="flex flex-col justify-center text-white font-bold"
						style={{ minHeight: 46.5 }}
					>
						<span> {this.props.data.title} </span>
					</div>
				</td>

				<td className='p-0'>
					<div className='w-full flex items-center justify-between '>
						<div className='h-[20px] top-[20%] border-l border-l-[#9CA3AF33]/[0.20]'></div>
						<div className='pr-2 pl-2'>
							<div className='text-[12px] font-medium text-white w-fit'>
								<span>{this.props.data.value !== null ?
									this.props.intl.formatNumber(this.props.data.value) : 'N/A'}</span>
							</div>
							{
								this.props.data.last_year &&
								<div className="text-[12px] font-medium text-emerald-400 w-fit">
									<span>{this.props.data.last_year_value !== null ?
										this.props.intl.formatNumber(this.props.data.last_year_value) : 'N/A'}</span>
								</div>}
						</div>
						<div className='h-[20px] top-[20%] border-l border-l-[#9CA3AF33]/[0.20]'></div>
					</div>
				</td >


				<td className='w-[125px] p-0'>
					<div className='w-full flex items-center justify-between'>
						<div></div>
						<div className='w-fit'>
							<div className='text-[12px] font-medium text-white text-center whitespace-nowrap'>
								{
									this.props.data.function === 'Cumulative Sum' & this.props.data.value === 0 ? '-' :
										this.props.data.value !== null ?
											this.props.intl.formatDate(
												toZonedTime(toDate(this.props.data.max_date), this.props.data.timezone),
												{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) : 'N/A'
								}
							</div>
							<div className="text-[12px] font-medium text-emerald-400 text-center whitespace-nowrap">
								{
									this.props.data.last_year &&
									(
										this.props.data.function === 'Cumulative Sum' & this.props.data.last_year_value === 0 ?
											'-' :
											<span>{this.props.data.last_year_value !== null ?
												this.props.intl.formatDate(toZonedTime(toDate(this.props.data.last_year_max_date), this.props.data.timezone), { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) : 'N/A'}</span>
									)}
							</div>

						</div>
						<div className='h-[20px] top-[20%] border-l border-l-[#9CA3AF33]/[0.20]'></div>
					</div>
				</td >


				<td className="p-2">
					<div className='h-full w-full flex justify-center items-center'>
						<div
							className="w-fit text-center text-[#10B981] text-[12px] rounded-[24px] bg-[#10B981] pr-[6px] pl-[6px] pt-[3px] pb-[3px] bg-opacity-10 font-semibold"
						>
							{range}
						</div>
					</div>
				</td>
			</tr >
		);
	}
}



class Stat extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stats: [],
			editing: false,
			action: 'new',
			// current: {
			// 	calculation: '',
			// 	sensors: [],
			// 	extract: {},
			// 	em50_flow_meter: false,
			// 	location_marker: {},
			// 	weather_variable: '',
			// 	graph: {},
			// 	function: '',
			// 	title: '',
			// 	variables: {
			// 		formula: 'x',
			// 	},
			// 	last_year: false,
			// 	reset_on: '',
			// },

			current: {},
			extracts: [],
			extractsCopy: [],
			//default date range on StatDialog
			daterange: { start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
			loading: false,
			graphs: [],
			message: null
		}

		this.editStat = this.editStat.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.createStat = this.createStat.bind(this);
		this.deleteStat = this.deleteStat.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateStat = this.updateStat.bind(this);
		this.initNewStat = this.initNewStat.bind(this);
		this.loadGraphsList = this.loadGraphsList.bind(this);
		this.handleGraphFilterChange = this.handleGraphFilterChange.bind(this);
		this.handleExtractFilterChange = this.handleExtractFilterChange.bind(this);
		this.renameWidget = this.renameWidget.bind(this);
		this.getMarker = this.getMarker.bind(this);
	}

	handleGraphFilterChange(event) {

		this.setState(() => {
			return {
				graphs: this.graphsListSource.slice().filter(item => {
					let filterText = event.filter.value,
						itemText = `${item.label.toLowerCase()} ${item.chart.name.toLowerCase()} [${item.calculation.toLowerCase()}]`;

					for (let i in filterText.split(' ')) {
						let textSplit = filterText.split(' ')[i];
						// if (item.name.toLowerCase().indexOf(textSplit) !== -1 || item.device.name.toLowerCase().indexOf(textSplit) !== -1 || item.port.indexOf(textSplit) !== -1)
						if (itemText.indexOf(textSplit) !== -1)
							return true
						else
							return false
					}
					return true;
				})
			};
		});
	}

	handleExtractFilterChange(event) {
		const text = event.filter.value.toLowerCase();
		let extracts = this.state.extractsCopy.filter((extract) => {
			if (extract.description.toLowerCase().indexOf(text) !== -1) {
				return true
			}
		})
		this.setState({
			extracts: extracts
		})
	}

	editStat(statId) {
		if (!this.props.editWidgets) {
			window.alert(this.props.intl.formatMessage({ id: 'app.stat.permissionAlert', defaultMessage: "You do not have permission to edit stats!" }))
			return
		}
		// let current, stats = this.state.stats.slice();

		//find the stat 
		// for (let i in stats){

		// 	if (stats[i].id === statId){
		// 		current = stats[i];
		// 		break;
		// 	}
		// }

		this.setState({ editing: true, action: 'loading' });

		let url = `${BASEURI}/api/stats/get/${statId}/`,
			options = {
				method: 'GET',
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {
						return response.json();
					})
					.then((current) => {
						let sensorsExtracts = [];

						let daterange = {
							start: new Date(current.min_date),
							end: new Date(current.max_date)
						}

						if (this.state.graphs.length === 0)
							this.loadGraphsList();

						if (current.calculation === 'Forecast') {
							let markerObject = this.getMarker(current.location_marker);
							current.location_marker = markerObject;
						}
						if (['Raw', 'Custom Formula'].indexOf(current.calculation) !== -1)

							if (current?.sensors?.length > 0) {
								this.props.handleStationChange({}, current.sensors[0].device)

								current?.sensors?.forEach((s) => {
									sensorsExtracts = sensorsExtracts.concat(s.extract)
								})
							}

						this.setState({
							// editing: true,
							extracts: sensorsExtracts,
							extractsCopy: sensorsExtracts,
							action: 'edit',
							current: current,
							daterange: daterange
						});

					}).catch(error => console.error('Error:', error));

			});
	}

	loadGraphsList() {

		let url = `${BASEURI}/api/allgraphs/`,
			options = {
				method: 'GET',
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {
						return response.json();
					})
					.then((graphsList) => {
						if (graphsList.length > 0)
							this.setState({
								graphs: graphsList,
							});
						this.graphsListSource = graphsList;
					})
					.catch(error => console.error('Error:', error));

			});

	}

	//set marker object as as value
	getMarker(id) {
		for (let i in this.props.markers) {
			if (this.props.markers[i].id === id) {
				return this.props.markers[i];
			};
		};
	}

	componentDidMount() {
		this.setState({
			stats: this.props.widget.stats.slice(),
			sensors: this.props.sensors ? this.props.sensors.slice() : []
		});
	}


	deleteStat(stat) {

		// Get delete confirmation.
		if (!window.confirm(
			this.props.intl.formatMessage({ id: 'app.stat.deleteMessage', defaultMessage: 'Are you sure you want to delete stat?' })
		))
			return

		this.setState({ loading: true, action: 'delete' });

		let url = `${BASEURI}/api/stats/rud/${stat}/`,
			options = {
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json; charset=utf-8"
				}
			}

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then(response => {
						let statList = this.state.stats.slice();
						let statToDelete = null;
						for (let i = 0; i < statList.length; i++) {
							if (statList[i].id === stat) {
								statToDelete = i;
								break;
							}
						}

						statList.splice(statToDelete, 1);

						this.setState({ stats: statList, editing: false, loading: false, current: {} });
					});

			});
	}

	openModal() {
		this.setState({
			editing: true
		});
	}

	closeModal() {
		this.setState({
			editing: false
		});
	}

	validateStat(stat) {

		if (!stat.calculation || stat.calculation === '') {
			this.setState({
				loading: false,
				message: this.props.intl.formatMessage({ id: 'app.stat.calculationValidationError', defaultMessage: 'Please select calculation type!' })
			});
			return false
		}

		if (!stat?.function || stat?.function === '') {
			this.setState({
				loading: false,
				message: this.props.intl.formatMessage({ id: 'app.stat.functionValidationError', defaultMessage: 'Please select function type!' })
			});
			return false
		}


		if (['Raw', 'Custom Formula'].indexOf(stat.calculation) !== -1) {
			if (!stat?.sensors || stat?.sensors?.length === 0) {
				this.setState({
					loading: false,
					message: this.props.intl.formatMessage({ id: 'app.stat.sensorSelectMessage', defaultMessage: 'Please select at least one sensor!' })
				});
				return false
			}

			if (!stat?.extract?.hasOwnProperty('id')) {
				this.setState({
					loading: false,
					message: this.props.intl.formatMessage({ id: 'app.stat.extractValidationError', defaultMessage: 'Please select extract!' })
				});
				return false
			}
		}

		if (!stat.title || stat.tile === '') {
			this.setState({
				loading: false,
				message: this.props.intl.formatMessage({ id: 'app.stat.titleValidationError', defaultMessage: 'Please provide title!' })
			});
			return false
		}

		if (!stat?.variables?.formula || !stat?.variables?.formula === '') {
			this.setState({
				loading: false,
				message: this.props.intl.formatMessage({ id: 'app.stat.formulaValidationError', defaultMessage: 'Please provide formula to apply to data!' })
			});
			return false
		}
		if (stat.function === "Cumulative Sum" & (!stat?.reset_on || stat?.reset_on === '')) {
			this.setState({
				loading: false,
				message: this.props.intl.formatMessage({ id: 'app.stat.resetValidationError', defaultMessage: 'Please provide reset date!' })
			});
			return false
		}
		if (stat?.calculation === 'Forecast') {

			if (!stat?.weather_variable || stat?.weather_variable === '') {
				this.setState({
					loading: false,
					message: this.props.intl.formatMessage({ id: 'app.stat.weatherVariableValidationError', defaultMessage: 'Please select weather variable!' })
				});
				return false
			}

			if (!stat?.location_marker?.hasOwnProperty('id')) {
				this.setState({
					loading: false,
					message: this.props.intl.formatMessage({ id: 'app.stat.locationMarkerValidationError', defaultMessage: 'Please select marker!' })
				});
				return false
			}
		}

		if (stat?.calculation === 'From Graph' & !stat?.graph?.hasOwnProperty('id')) {
			this.setState({
				loading: false,
				message: this.props.intl.formatMessage({ id: 'app.stat.graphValidationError', defaultMessage: 'Please select graph!' })
			});
			return false
		}

		return true

	}

	createStat() {

		if (!this.validateStat(this.state.current)) return

		this.setState({ loading: true, message: '' });
		let newStat = Object.assign({}, this.state.current);
		if (newStat.calculation === 'From Graph') {
			newStat.graph = newStat.graph.id;
			newStat.widget = this.props.statWidgetId;
		} else if (newStat.calculation === 'Forecast') {
			newStat.widget = this.props.statWidgetId;
			newStat.location_marker = newStat.location_marker.id;
		} else {


			newStat.widget = this.props.statWidgetId;
			newStat.extract = newStat.extract.id;


			let sensors = [];
			for (let i in newStat.sensors)
				sensors.push(newStat.sensors[i].id);

			newStat.sensors = sensors;
		}

		let url = `${BASEURI}/api/stats/create/${this.props.statWidgetId}/`,
			options = {
				method: 'POST',
				body: JSON.stringify(newStat),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {

						if (!response.ok) {
							this.setState({ loading: false, message: this.props.intl.formatMessage({ id: 'app.stat.errorMessage', defaultMessage: 'Something went wrong please try again later.' }) });
							throw new Error(response.statusText, response.status);
						}
						if (response.status !== 204)
							return response.json();

					})
					.then(stat => {
						let stats = this.state.stats.slice();
						stat.sensors = this.state.current.sensors;
						stat.extract = this.state.current.extract;
						stats.push(stat);
						this.setState({ stats: stats, editing: false, loading: false });

						// this.setState((state, props) => {
						//   let stats = state.stats.slice();
						//   stats.push(stat);
						//   return {stats:stats};
						// });

						// this.closecreateStatDialog();

					})
					.catch(error => console.log('Error:', error));

			});

	}

	updateStat() {

		if (!this.validateStat(this.state.current)) return

		this.setState({ loading: true, message: '' });

		let statToUpdate = Object.assign({}, this.state.current);
		statToUpdate.widget = this.props.statWidgetId;
		if (statToUpdate.calculation === 'From Graph') {
			statToUpdate.graph = statToUpdate.graph.id;
			statToUpdate.extract = null;
			statToUpdate.sensors = [];
			statToUpdate.weather_variables = null;
			statToUpdate.location_marker = null;

		} else if (statToUpdate.calculation === 'Forecast') {
			statToUpdate.extract = null;
			statToUpdate.sensors = [];
			statToUpdate.graph = null;
			statToUpdate.location_marker = statToUpdate.location_marker.id;
		} else {


			statToUpdate.extract = statToUpdate.extract.id;


			let sensors = [];

			for (let i in statToUpdate.sensors)
				sensors.push(statToUpdate.sensors[i].id);

			statToUpdate.sensors = sensors;

			statToUpdate.graph = null;
			statToUpdate.weather_variables = null;
			statToUpdate.location_marker = null;

		}


		let url = `${BASEURI}/api/stats/rud/${statToUpdate.id}/`,
			options = {
				method: 'PUT',
				body: JSON.stringify(statToUpdate),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => {
						if (!response.ok) {
							this.setState({ loading: false, message: this.props.intl.formatMessage({ id: 'app.stat.errorMessage', defaultMessage: 'Something went wrong please try Again!' }) });
							throw new Error(response.statusText, response.status);
						}
						if (response.status !== 204)
							return response.json();
					})
					.then(stat => {


						let statList = this.state.stats.slice();

						let statToReplace = null;
						for (let i = 0; i < statList.length; i++) {
							if (statList[i].id === stat.id) {
								statToReplace = i;
								break;
							}
						}

						statList.splice(statToReplace, 1, stat);

						// statList[statToReplace] = stat;

						this.setState({ stats: statList, editing: false, loading: false });

					})
					.catch(error => console.log('Error:', error));

			});

	}

	renameWidget(id, newName) {
		let widget = {
			id: id,
			name: newName
		}
		let url = `${BASEURI}/api/stats/widget/rud/${widget.id}/`,
			options = {
				method: 'PUT',
				body: JSON.stringify(widget),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
				}
			};

		getAuthToken()
			.then(token => token)
			.catch(token => token)
			.then(token => {

				options.headers.Authorization = `Bearer  ${token}`;

				fetch(url, options)
					.then((response) => response.json())
					.catch(error => console.log('Error:', error));

			});

	}

	handleChange(event, range = undefined) {
		if (range) {
			this.setState(state => {
				let current = state.current;
				current.range = range;
				let min_date;
				let max_date;

				if (range === 180)
					min_date = new Date(new Date().setMonth(new Date().getMonth() - (range / 30)));
				else if (range === 365)
					min_date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
				else
					min_date = new Date(new Date().setDate(new Date().getDate() - range));

				max_date = new Date();

				return {
					daterange: { start: min_date, end: max_date },
					current: current
				};
			})
			return;
		}

		let name = event.target.name, value = event.target.value;
		if (event.target.props.name === 'daterange')
			this.setState(state => {

				let current = state.current;
				current.min_date = value.start;
				current.max_date = value.end;
				current.range = null;
				return {
					daterange: value,
					current: current
				};
			}
			);
		else if (event.target.name === 'formula')
			this.setState(state => {

				let current = state.current;
				current.variables.formula = value;

				return { current: current };
			});
		else
			this.setState(state => {

				let current = state.current;
				current[name] = value;

				// We don't calculate last year for (From Graph, Forecast and Cumulative Sum)
				if (['From Graph', 'Forecast'].indexOf(current.calculation) !== -1) {
					current.last_year = false;
				}

				return { current: current };
			});


		if (event.target.name === 'sensors') {
			if (event.target.value.length > 0) {

				let url = `${BASEURI}/api/extracts/${event.target.value[0].id}/`,

					options = {
						method: 'GET',
						headers: {
							"Content-Type": "application/json; charset=utf-8",
						}
					};

				getAuthToken()
					.then(token => token)
					.catch(token => token)
					.then(token => {

						options.headers.Authorization = `Bearer  ${token}`;

						fetch(url, options)
							.then((response) => response.json())
							.then((extracts) => {
								this.setState({
									extracts: extracts,
									extractsCopy: extracts
								});
							})
							.catch(error => console.log('Error:', error));
					});
			}
		}

	}

	initNewStat() {

		let newStat = {

			variables: {
				formula: 'x'
			},
			color: '#000000',
			min_date: new Date(new Date().setDate(new Date().getDate() - 7)),
			max_date: new Date()
		}

		this.loadGraphsList();

		this.setState({
			editing: true,
			action: 'new',
			current: { ...this.state.current, ...newStat }
		});
	}

	render() {
		let stats = this.state.stats.map((stat) =>
			<StatItem
				intl={this.props.intl}
				station={this.props.station}
				handleStationChange={this.props.handleStationChange}
				onClick={this.editStat}
				statId={stat.id}
				key={stat.id}
				data={stat}
			/>);

		return (
			<div className='sm:w-[350px]'>
				<StatMenu
					intl={this.props.intl}
					renameWidget={this.renameWidget}
					name={this.props.widget.name}
					delete={this.props.delete}
					widgetId={this.props.statWidgetId}
					initNewStat={this.initNewStat} />

				<Modal>
					<StatModalForm
						message={this.state.message}
						intl={this.props.intl}
						stations={this.props.stations}
						station={this.props.station}
						setStations={this.props.setStations}
						allStations={this.props.allStations}
						handleStationChange={this.props.handleStationChange}
						visible={this.state.editing}
						close={this.closeModal}
						action={this.state.action}
						current={this.state.current}
						updateStat={this.updateStat}
						createStat={this.createStat}
						delete={this.deleteStat}
						statTitle={this.state.action === 'edit' ? this.state.current.title : ''}
						sensors={this.props.sensors}
						statId={this.state.current ? this.state.current.id : null}
						handleChange={this.handleChange}
						extracts={this.state.extracts}
						daterange={this.state.daterange}
						loading={this.state.loading}
						graphs={this.state.graphs}
						handleFilterChange={this.props.filter}
						handleGraphFilterChange={this.handleGraphFilterChange}
						weatherVariables={this.props.weatherVariables}
						markers={this.props.markers}
						getMarker={this.getMarker}
						handleMarkersFilterChange={this.props.handleMarkersFilterChange}
						noData={this.props.noData}
						loadingSensors={this.props.loadingSensors}
						loadingMarkers={this.props.loadingMarkers}
						loadingVariables={this.props.loadingWeatherVariables}
						handleExtractFilterChange={this.handleExtractFilterChange}
					/>
				</Modal>
				<table className="w-full rounded-[15px] drop-shadow-md bg-[#1E293B]" style={{ cursor: 'pointer' }}>
					<tbody>
						{stats}
					</tbody>
				</table>
			</div>
		);
	}

}


export default Stat;